export default {
  ["receive"]: "受け取る",
  ["mining_pool"]: "マイニングプール",
  ["account"]: "アカウント",
  ["pool_data"]: "プールデータ",
  ["total_output"]: "総出力",
  ["valid_node"]: "有効なノード",
  ["participant"]: "参加者",
  ["user_revenue"]: "ユーザー収益",
  ["activity"]: "アクティビティ",
  ["mining"]: "マイニング",
  ["liquidity"]: "流動性マイニング収入",
  ["user_output"]: "ユーザー出力",
  ["address"]: "住所",
  ["quantity"]: "量",
  ["help_center"]: "ヘルプセンター",
  ["hep_center_tip"]: "それがあなたに役立つことを願っています",
  ["question1"]: "どうすれば参加できますか?",
  ["answer1"]: "非破壊および非保証の流動性マイニングに参加するには、バウチャーを受け取るためにETHマイナーの料金を支払う必要があり、ETHウォレットアドレスは一度だけ請求する必要があります。成功後、マイニング権限を自動的に開きます。",
  ["question2"]: "どうすればお金を引き出すことができますか?",
  ["answer2"]: "毎日生産されるコインをUSDTに変換してから、引き出しを開始できます。 USDTの引き出しは、ノードに追加したウォレットアドレスに自動的に送信され、他のアドレスはサポートされていません。",
  ["question3"]: "収入の計算方法?",
  ["answer3"]: "正常に参加すると、スマートコントラクトはノードを介して住所の計算を開始し、収入の計算を開始します。",
  ["audit_report"]: "監査報告書",
  ["audit_report_tip"]: "安全な監査レポートがあります",
  ["partner"]: "相棒",
  ["partner_tip"]: "私たちのビジネスパートナー",
  ["my_account"]: "マイアカウント",
  ["mining_status"]: "マイニングステータス",
  ["wallet_balance"]: "ウォレットの残高",
  ["exchangeable"]: "交換可能",
  ["withdrawable"]: "引き出し可能",
  ["share_dividends"]: "配当金を共有する",
  ["my_level"]: "私のレベル",
  ["level"]: "レベル",
  ["hash_rate"]: "ハッシュレート",
  ["output_interest"]: "出力金利",
  ["output_interest_rate"]: "出力金利",
  ["output_interest_cycle"]: "出力金利サイクル",
  ["level_details"]: "レベルの詳細",
  ["pair_price"]: "ペア価格",
  ["exchange"]: "交換",
  ["withdraw"]: "取り下げる",
  ["redeem_all"]: "すべて引き換える",
  ["total_balance"]: "総合収支",
  ["record"]: "記録",
  ["shared"]: "共有",
  ["output"]: "出力",
  ["back"]: "戻る",
  ["no-data"]: "データなし",
  ["received"]: "受け取った",
  ["transfer"]: "移行",
  ["share_friends"]: "友達を共有する",
  ["my_share_link"]: "私の共有リンク",
  ["copy"]: "副本",
  ["share_tip"]: "招待リンクを送信すると、友達があなたのリンクを介してノードに参加し、寛大なトークン報酬を獲得できます",
  ["join_tip"]: "参加しました",
  ["copySuccess"]: "リンクがコピーされました",
  "apply_mining_pool_rewards": "マイニングプール報酬を申請",
  "standard": "標準",
  "required": "必要",
  "countdown": "カウントダウン",
  "host_day": "日",
  "reward": "報酬",
  ["i_know"]: "わかります",
["popup_miniing_title"]: "マイニングプールリワードを申請",
["popup_miniing_conn1"]: "ERC-20 スマートコントラクト",
["popup_miniing_conn2"]: "ERC-20 スマートコントラクトのマイニングプールが次のETH共有イベントを開始します。今すぐ参加を申請してください。イベントが開始した後、流動性提供者は複数のリワードを共有できます。",
["popup_miniing_btn"]: "リワードを申請",
"recharge": "リチャージ",
  "recharge_currency": "入金アドレス",
  "recharge_scan code transfer": "QRコードを保存",
  "recharge_amount": "入金額",
  "please enter": "入力してください",
  "recharge_upload": "画像をアップロードするにはクリック",
  "confirm": "確認",
  "rachange_link_coinbase": "Coinbaseの公式リチャージチャネル",
  "rachange_link_binance": "Binanceの公式リチャージチャネル",
  "rachange_link_huobi": "Huobiの公式リチャージチャネル",
  "cancel": "キャンセル",
  "addressCopySuccess": "アドレスがコピーされました",
  "recharge_record": "リチャージ履歴",
  "all": "全て",
  "processing": "保留中",
  "credited": "成功",
  "fail": "失敗",
  "amount": "金額",
  "state": "状態",
  "time": "時間",
  "record_tip": "備考"
}