export default {
  "receive": "接收",
  "mining_pool": "礦池",
  "account": "賬戶",
  "pool_data": "池數據",
  "total_output": "總產出",
  "valid_node": "有效節點",
  "participant": "參與者",
  "user_revenue": "用戶收入",
  "activity": "活動",
  "mining": "挖礦",
  "liquidity": "流動性挖礦收入",
  "user_output": "用戶產出",
  "address": "地址",
  "quantity": "數量",
  "help_center": "幫助中心",
  "hep_center_tip": "希望能幫到你",
  "question1": "我如何加入？",
  "answer1": "參與無損和無擔保的流動性挖礦，您需要支付ETH礦工費以接收憑證，且ETH錢包地址只需領取一次。成功後自動開啟挖礦權限。",
  "question2": "我如何提取資金？",
  "answer2": "您可以將每日產生的幣轉換為USDT，然後發起提現。USDT提現將自動發送到您添加到節點的錢包地址，不支持其他地址。",
  "question3": "如何計算收入？",
  "answer3": "當您成功加入後，智能合約通過節點開始計算您的地址，並開始計算收入。",
  "audit_report": "審計報告",
  "audit_report_tip": "我們有一份安全的審計報告",
  "partner": "合作夥伴",
  "partner_tip": "我們的商業夥伴",
  "my_account": "我的賬戶",
  "mining_status": "挖礦狀態",
  "wallet_balance": "錢包餘額",
  "exchangeable": "可兌換",
  "withdrawable": "可提現",
  "share_dividends": "分享紅利",
  "my_level": "我的等級",
  "level": "等級",
  "hash_rate": "算力",
  "output_interest": "產出利息",
  "output_interest_rate": "產出利率",
  "output_interest_cycle": "產出利息周期",
  "level_details": "等級詳情",
  "pair_price": "對價",
  "exchange": "兌換",
  "withdraw": "提現",
  "redeem_all": "全部兌換",
  "total_balance": "總餘額",
  "record": "記錄",
  "shared": "已分享",
  "output": "產出",
  "back": "返回",
  "no-data": "無數據",
  "received": "已接收",
  "transfer": "轉賬",
  "share_friends": "分享好友",
  "my_share_link": "我的分享鏈接",
  "copy": "複製",
  "share_tip": "發送您的邀請鏈接，朋友通過您的鏈接加入節點，您將獲得豐厚的代幣獎勵",
  "join_tip": "您已加入",
  "copySuccess": "鏈接已複製",
  "apply_mining_pool_rewards": "申請礦池獎勵",
  "standard": "標準",
  "required": "需要",
  "countdown": "倒計時",
  "host_day": "天",
  "reward": "獎勵",
  "i_know": "我知道了",
  "popup_miniing_title": "申請礦池獎勵",
  "popup_miniing_conn1": "ERC-20智能合約",
  "popup_miniing_conn2": "ERC-20智能合約礦池即將開始下一個ETH分享活動。現在申請加入，活動開始後，流動性提供者可以分享多個獎勵。",
  "popup_miniing_btn": "申請獎勵",
  "recharge": "充值",
  "recharge_currency": "存款地址",
  "recharge_scan code transfer": "保存QR",
  "recharge_amount": "存款金額",
  "please enter": "請輸入",
  "recharge_upload": "點擊上傳圖片",
  "confirm": "確認",
  "rachange_link_coinbase": "Coinbase官方充值渠道",
  "rachange_link_binance": "Binance官方充值渠道",
  "rachange_link_huobi": "Huobi官方充值渠道",
  "cancel": "取消",
  "addressCopySuccess": "地址已復製",
  "recharge_record": "充值記錄",
  "all": "全部",
  "processing": "處理中",
  "credited": "成功",
  "fail": "失敗",
  "amount": "金額",
  "state": "狀態",
  "time": "時間",
  "record_tip": "備註"
}