<template>
  <div id="app">
    <keep-alive>
      <router-view />
    </keep-alive>
    
  </div>
</template>

<script>
import Web3 from "web3";
import JSEncrypt from "jsencrypt";
import { Notify, Toast } from "vant";

export default {
  data() {
    return {
      showCover: true,
    };
  },
  async created() {
    
  },
  methods: {},
};
</script>


<style>

</style>
