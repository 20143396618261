export default {
  dateFormat(date, format = "yyyy-MM-dd") {
    const unit = {
      "y+": date.getFullYear(),
      "M+": date.getMonth() + 1,
      "d+": date.getDate(),
      "h+": date.getHours(),
      "m+": date.getMinutes(),
      "s+": date.getSeconds()
    }

    for(let key in unit) {
      if(new RegExp(`(${key})`).test(format)) {
        const matched = RegExp.$1;
        format = format.replace(
          matched,
          matched.legnth === 1 ? unit[key] : ("0000" + unit[key]).slice(-matched.length)
        )
      }
    }

    return format
  }
}