export default {
  "receive": "รับ",
  "mining_pool": "พูลขุดเหมือง",
  "account": "บัญชี",
  "pool_data": "ข้อมูลพูล",
  "total_output": "ผลลัพธ์รวม",
  "valid_node": "โหนดที่ถูกต้อง",
  "participant": "ผู้ร่วมกิจกรรม",
  "user_revenue": "รายได้ของผู้ใช้",
  "activity": "กิจกรรม",
  "mining": "การขุดเหมือง",
  "liquidity": "รายได้จากการขุดเหมือง Likvidity",
  "user_output": "ผลลัพธ์ของผู้ใช้",
  "address": "ที่อยู่",
  "quantity": "ปริมาณ",
  "help_center": "ศูนย์ช่วยเหลือ",
  "hep_center_tip": "หวังว่าจะช่วยคุณ",
  "question1": "ฉันจะเข้าร่วมได้อย่างไร？",
  "answer1": "เพื่อเข้าร่วมการขุดเหมือง Likvidity ที่ไม่ทำลายและไม่รับประกันคุณต้องชำระค่าธรรมเนียมขุดเหมือง ETH เพื่อรับคูปองและที่อยู่กระเป๋าเงิน ETH เพียงครั้งเดียวเท่านั้น หลังจากเสร็จสิ้น จะเปิดสิทธิ์ในการขุดเหมืองโดยอัตโนมัติ",
  "question2": "ฉันจะถอนเงินได้อย่างไร？",
  "answer2": "คุณสามารถแปลงเหรียญที่ผลิตขึ้นทุกวันเป็น USDT แล้วเริ่มทำการถอนเงิน การถอนเงิน USDT จะถูกส่งโดยอัตโนมัติไปที่ที่อยู่กระเป๋าเงินที่คุณเพิ่มเข้าไปในโหนด และไม่รองรับที่อยู่อื่น",
  "question3": "วิธีการคำนวณรายได้คืออย่างไร？",
  "answer3": "เมื่อคุณเข้าร่วมเรียบร้อยแล้ว สัญญาฉลากจะเริ่มคำนวณที่อยู่ของคุณผ่านโหนดและเริ่มคำนวณรายได้",
  "audit_report": "รายงานการตรวจสอบ",
  "audit_report_tip": "เรามีรายงานการตรวจสอบที่ปลอดภัย",
  "partner": "พาร์ทเนอร์",
  "partner_tip": "พาร์ทเนอร์ทางธุรกิจของเรา",
  "my_account": "บัญชีของฉัน",
  "mining_status": "สถานะการขุดเหมือง",
  "wallet_balance": "ยอดคงเหลือในกระเป๋าเงิน",
  "exchangeable": "สามารถแลกเปลี่ยนได้",
  "withdrawable": "สามารถถอนได้",
  "share_dividends": "ปันผล",
  "my_level": "ระดับของฉัน",
  "level": "ระดับ",
  "hash_rate": "อัตราการขุดเหมือง",
  "output_interest": "ดอกเบี้ยผลผลิต",
  "output_interest_rate": "อัตราดอกเบี้ยผลผลิต",
  "output_interest_cycle": "รอบดอกเบี้ยผลผลิต",
  "level_details": "รายละเอียดระดับ",
  "pair_price": "ราคาคู่",
  "exchange": "แลกเปลี่ยน",
  "withdraw": "ถอน",
  "redeem_all": "แลกคืนทั้งหมด",
  "total_balance": "ยอดคงเหลือทั้งหมด",
  "record": "บันทึก",
  "shared": "แบ่งปัน",
  "output": "ผลลัพธ์",
  "back": "กลับ",
  "no-data": "ไม่มีข้อมูล",
  "received": "ได้รับแล้ว",
  "transfer": "โอน",
  "share_friends": "แบ่งปันเพื่อน",
  "my_share_link": "ลิงก์แบ่งปันของฉัน",
  "copy": "คัดลอก",
  "share_tip": "ส่งลิงก์เชิญของคุณ ทุกคนเข้าร่วมโหนดผ่านลิงก์ของคุณและคุณจะได้รับรางวัลโทเคนมูลค่าสูง",
  "join_tip": "คุณได้เข้าร่วมแล้ว",
  "copySuccess": "ลิงก์ถูกคัดลอกแล้ว",
  "apply_mining_pool_rewards": "ยื่นขอรับรางวัลพูลขุดเหมือง",
  "standard": "มาตรฐาน",
  "required": "จำเป็น",
  "countdown": "นับถอยหลัง",
  "host_day": "วัน",
  "reward": "รางวัล",
  "i_know": "ทราบ",
  "popup_miniing_title": "ยื่นขอรับรางวัลพูลขุดเหมือง",
  "popup_miniing_conn1": "สัญญาฉลาก ERC-20",
  "popup_miniing_conn2": "พูลขุดเหมืองสัญญาฉลาก ERC-20 กำลังเริ่มต้นกิจกรรมแบ่งปัน ETH ครั้งถัดไป สมัครเข้าร่วมเดี๋ยวนี้ หลังจากที่กิจกรรมเริ่มต้น ผู้ให้บริการ Likvidity สามารถแบ่งปันรางวัลได้หลายรายการ",
  "popup_miniing_btn": "ยื่นขอรับรางวัล",
  "recharge": "เติมเงิน",
  "recharge_currency": "ที่อยู่ฝากเงิน",
  "recharge_scan code transfer": "บันทึก QR",
  "recharge_amount": "จำนวนเงินฝาก",
  "please enter": "กรุณาใส่",
  "recharge_upload": "คลิกเพื่ออัปโหลดรูปภาพ",
  "confirm": "ยืนยัน",
  "rachange_link_coinbase": "ช่องทางเติมเงินอย่างเป็นทางการของ Coinbase",
  "rachange_link_binance": "ช่องทางเติมเงินอย่างเป็นทางการของ Binance",
  "rachange_link_huobi": "ช่องทางเติมเงินอย่างเป็นทางการของ Huobi",
  "cancel": "ยกเลิก",
  "addressCopySuccess": "ที่อยู่ถูกคัดลอกแล้ว",
  "recharge_record": "ประวัติการเติมเงิน",
  "all": "ทั้งหมด",
  "processing": "รอดำเนินการ",
  "credited": "สำเร็จ",
  "fail": "ล้มเหลว",
  "amount": "จำนวนเงิน",
  "state": "สถานะ",
  "time": "เวลา",
  "record_tip": "หมายเหตุ"
}