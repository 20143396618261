export default {
  "receive": "Получить",
  "mining_pool": "Майнинговый пул",
  "account": "Аккаунт",
  "pool_data": "Данные пула",
  "total_output": "Общий вывод",
  "valid_node": "Действительный узел",
  "participant": "Участник",
  "user_revenue": "Доход пользователя",
  "activity": "Активность",
  "mining": "Майнинг",
  "liquidity": "доход от ликвидного майнинга",
  "user_output": "Вывод пользователя",
  "address": "Адрес",
  "quantity": "Количество",
  "help_center": "Центр помощи",
  "hep_center_tip": "надеюсь, это поможет вам",
  "question1": "Как я могу присоединиться?",
  "answer1": "Чтобы участвовать в безубыточном и негарантированном ликвидном майнинге, вам нужно заплатить комиссию майнера ETH, чтобы получить ваучер, и адрес кошелька ETH нужно запрашивать только один раз. После успеха майнинговые разрешения автоматически открываются.",
  "question2": "Как я могу вывести деньги?",
  "answer2": "Вы можете конвертировать ежедневно производимые монеты в USDT, а затем инициировать вывод. Вывод USDT будет автоматически отправлен на адрес кошелька, который вы добавили в узел, и другие адреса не поддерживаются.",
  "question3": "Как рассчитывается доход?",
  "answer3": "Когда вы успешно присоединяетесь, смарт-контракт начинает рассчитывать ваш адрес через узел и начинает рассчитывать доход.",
  "audit_report": "Аудиторский отчет",
  "audit_report_tip": "у нас есть безопасный аудиторский отчет",
  "partner": "Партнер",
  "partner_tip": "наш деловой партнер",
  "my_account": "Мой аккаунт",
  "mining_status": "Статус майнинга",
  "wallet_balance": "Баланс кошелька",
  "exchangeable": "Обмениваемый",
  "withdrawable": "Выводимый",
  "share_dividends": "Дивиденды по акциям",
  "my_level": "Мой уровень",
  "level": "Уровень",
  "hash_rate": "Хешрейт",
  "output_interest": "Процентный доход",
  "output_interest_rate": "Процентная ставка",
  "output_interest_cycle": "Цикл процентного дохода",
  "level_details": "Детали уровня",
  "pair_price": "Цена пары",
  "exchange": "Обмен",
  "withdraw": "Вывод",
  "redeem_all": "Погасить все",
  "total_balance": "Общий баланс",
  "record": "Запись",
  "shared": "Поделился",
  "output": "Вывод",
  "back": "Назад",
  "no-data": "Нет данных",
  "received": "Получено",
  "transfer": "Перевод",
  "share_friends": "Поделиться с друзьями",
  "my_share_link": "Моя ссылка для приглашения",
  "copy": "Копировать",
  "share_tip": "Отправьте вашу ссылку для приглашения, друзья присоединяются к узлу через вашу ссылку, и вы получите щедрые награды в токенах",
  "join_tip": "Вы присоединились",
  "copySuccess": "Ссылка скопирована",
  "apply_mining_pool_rewards": "Подать заявку на вознаграждения майнингового пула",
  "standard": "Стандарт",
  "required": "Требуется",
  "countdown": "Обратный отсчет",
  "host_day": "День",
  "reward": "Награда",
  "i_know": "Я понял",
  "popup_miniing_title": "Подать заявку на вознаграждения майнингового пула",
  "popup_miniing_conn1": "Смарт-контракт ERC-20",
  "popup_miniing_conn2": "Майнинговый пул смарт-контракта ERC-20 скоро начнет следующее событие по распределению ETH. Подайте заявку на участие сейчас. После начала события поставщики ликвидности смогут поделиться множеством наград",
  "popup_miniing_btn": "Подать заявку на вознаграждения",
  "recharge": "Пополнение",
  "recharge_currency": "Адрес депозита",
  "recharge_scan code transfer": "Сохранить QR-код",
  "recharge_amount": "Сумма депозита",
  "please enter": "Пожалуйста, введите",
  "recharge_upload": "Нажмите для загрузки изображений",
  "confirm": "Подтвердить",
  "rachange_link_coinbase": "Официальный канал пополнения Coinbase",
  "rachange_link_binance": "Официальный канал пополнения Binance",
  "rachange_link_huobi": "Официальный канал пополнения Huobi",
  "cancel": "Отмена",
  "addressCopySuccess": "Адрес скопирован",
  "recharge_record": "История пополнений",
  "all": "Все",
  "processing": "В ожидании",
  "credited": "Успех",
  "fail": "Неудача",
  "amount": "Сумма",
  "state": "Статус",
  "time": "Время",
  "record_tip": "Примечание"
}