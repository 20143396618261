import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import requestor from "@/utils/requestor"
import api from '@/api'
import i18n from '@/locales/index'
import { Toast, Dialog } from 'vant';
import GlobalFilter from '@/utils/filter'
import VueClipboard from 'vue-clipboard2';
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import '../src/styles/theme.css'

var psl = require('psl')
var parsed = psl.parse(location.host);
// import Vconsole from 'vconsole'
// let vConsole = new Vconsole()
// Vue.use(vConsole)

Vue.use(VueClipboard)
Vue.use(Toast);
Vue.use(Dialog);

const languageMap = {
  'zh-CN': 'zh',
  'zh-TW': 'tw',
  'en': 'en',
  'fr': 'fr',
  'de': 'de',
  'it': 'it',
  'ja': 'ja',
  'ko': 'ko',
  'pt-PT': 'pt',
  'ru': 'ru',
  'es': 'es',
  'th': 'th',
  'vi': 'vi',
  'tr': 'tr',
  'ind': 'ind'
}

Vue.use(Vant)

Vue.use(GlobalFilter)

Vue.prototype.$Toast = Toast

Vue.prototype.$Dialog = Dialog

Vue.config.productionTip = false

Vue.prototype.$api = api

Vue.prototype.$requestor = requestor

Vue.prototype.NProgress = NProgress

Vue.prototype.I18n = i18n

Vue.config.productionTip = false

Vue.prototype.setToken = value => {
  Vue.prototype.$requestor.setToken(value);
  localStorage.setItem(process.env.VUE_APP_TOKEN_KEY, value);
}
Vue.prototype.setLanguage = value => {
  Vue.prototype.$requestor.setLanguage(value);
  localStorage.setItem('language', value);
}
if (localStorage.getItem(process.env.VUE_APP_TOKEN_KEY)) {
  Vue.prototype.$requestor.setToken(localStorage.getItem(process.env.VUE_APP_TOKEN_KEY))
}
if (localStorage.getItem('language')) {
  Vue.prototype.$requestor.setLanguage(localStorage.getItem('language'))
  i18n.locale = localStorage.getItem('language');
} else {
  Vue.prototype.$requestor.setLanguage('en')
}
// console.log(location)
// console.log(process.env.VUE_APP_BASE_URL)
if (process.env.VUE_APP_BASE_URL != "") {
  Vue.prototype.$requestor.setBaseUrl(process.env.VUE_APP_BASE_URL)
} else {
  Vue.prototype.$requestor.setBaseUrl('https://api.' + parsed.domain + '/api')
}


Vue.prototype.$requestor.errorHandle[401] = () => {
  if (localStorage.getItem('token')) {
    location.reload()
  }
}


new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
