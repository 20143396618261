export default {
  "receive": "받기",
  "mining_pool": "마이닝 풀",
  "account": "계정",
  "pool_data": "풀 데이터",
  "total_output": "총 출력",
  "valid_node": "유효 노드",
  "participant": "참가자",
  "user_revenue": "사용자 수익",
  "activity": "활동",
  "mining": "마이닝",
  "liquidity": "유동성 마이닝 수익",
  "user_output": "사용자 출력",
  "address": "주소",
  "quantity": "수량",
  "help_center": "도움말 센터",
  "hep_center_tip": "도움이 되길 바랍니다",
  "question1": "어떻게 참여할 수 있나요?",
  "answer1": "무손실 및 비보증 유동성 마이닝에 참여하려면 ETH 마이너 수수료를 지불하여 바우처를 수령해야 하며, ETH 지갑 주소는 한 번만 요청하면 됩니다. 성공 후 마이닝 권한이 자동으로 열립니다.",
  "question2": "돈을 어떻게 인출하나요?",
  "answer2": "매일 생성되는 코인을 USDT로 변환한 후 인출을 시작할 수 있습니다. USDT 인출은 노드에 추가한 지갑 주소로 자동 전송되며 다른 주소는 지원되지 않습니다.",
  "question3": "수익은 어떻게 계산되나요?",
  "answer3": "성공적으로 참여하면 스마트 계약이 노드를 통해 귀하의 주소를 계산하고 수익을 계산하기 시작합니다.",
  "audit_report": "감사 보고서",
  "audit_report_tip": "안전한 감사 보고서가 있습니다",
  "partner": "파트너",
  "partner_tip": "우리의 비즈니스 파트너",
  "my_account": "내 계정",
  "mining_status": "마이닝 상태",
  "wallet_balance": "지갑 잔액",
  "exchangeable": "교환 가능",
  "withdrawable": "인출 가능",
  "share_dividends": "주식 배당",
  "my_level": "내 레벨",
  "level": "레벨",
  "hash_rate": "해시 속도",
  "output_interest": "출력 이자",
  "output_interest_rate": "출력 이자율",
  "output_interest_cycle": "출력 이자 주기",
  "level_details": "레벨 세부 정보",
  "pair_price": "페어 가격",
  "exchange": "교환",
  "withdraw": "인출",
  "redeem_all": "모두 상환",
  "total_balance": "총 잔액",
  "record": "기록",
  "shared": "공유됨",
  "output": "출력",
  "back": "뒤로",
  "no-data": "데이터 없음",
  "received": "수신됨",
  "transfer": "이체",
  "share_friends": "친구와 공유",
  "my_share_link": "내 공유 링크",
  "copy": "복사",
  "share_tip": "초대 링크를 보내세요. 친구가 귀하의 링크를 통해 노드에 가입하면 넉넉한 토큰 보상을 받게 됩니다.",
  "join_tip": "가입되었습니다",
  "copySuccess": "링크가 복사되었습니다",
  "apply_mining_pool_rewards": "마이닝 풀 보상 신청",
  "standard": "표준",
  "required": "필수",
  "countdown": "카운트다운",
  "host_day": "일",
  "reward": "보상",
  "i_know": "알겠습니다",
  "popup_miniing_title": "마이닝 풀 보상 신청",
  "popup_miniing_conn1": "ERC-20 스마트 계약",
  "popup_miniing_conn2": "ERC-20 스마트 계약 마이닝 풀은 다음 ETH 공유 이벤트를 시작할 예정입니다. 지금 참여 신청하세요. 이벤트 시작 후 유동성 공급자는 여러 보상을 공유할 수 있습니다.",
  "popup_miniing_btn": "보상 신청",
  "recharge": "충전",
  "recharge_currency": "입금 주소",
  "recharge_scan code transfer": "QR 저장",
  "recharge_amount": "입금 금액",
  "please enter": "입력하세요",
  "recharge_upload": "이미지 업로드를 클릭하세요",
  "confirm": "확인",
  "rachange_link_coinbase": "Coinbase 공식 충전 채널",
  "rachange_link_binance": "Binance 공식 충전 채널",
  "rachange_link_huobi": "Huobi 공식 충전 채널",
  "cancel": "취소",
  "addressCopySuccess": "주소가 복사되었습니다",
  "recharge_record": "충전 기록",
  "all": "전체",
  "processing": "처리 중",
  "credited": "성공",
  "fail": "실패",
  "amount": "금액",
  "state": "상태",
  "time": "시간",
  "record_tip": "참고"
}