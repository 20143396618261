export default {
  ["receive"]: "Nhận",
  ["mining_pool"]: "Nhóm khai thác",
  ["account"]: "Tài khoản",
  ["pool_data"]: 'Dữ liệu nhóm',
  ["total_output"]: "Tổng sản lượng",
  ["valid_node"]: "Nút hợp lệ",
  ["participant"]: "Người tham gia",
  ["user_revenue"]: "Doanh thu của người dùng",
  ["activity"]: "Hoạt động",
  ["mining"]: "Khai thác mỏ",
  ["liquidity"]: "thu nhập từ khai thác thanh khoản",
  ["user_output"]: "Đầu ra của người dùng",
  ["address"]: "Địa chỉ nhà",
  ["quantity"]: "Định lượng",
  ["help_center"]: "Trung tâm trợ giúp",
  ["hep_center_tip"]: "hy vọng nó sẽ giúp bạn",
  ["question1"]: "Tôi có thể tham gia bằng cách nào?",
  ["answer1"]: "Để tham gia khai thác thanh khoản không phá hủy và không đảm bảo, bạn cần phải trả phí cho người khai thác ETH để nhận được phiếu thưởng và địa chỉ ví ETH chỉ cần được xác nhận một lần. Tự động mở quyền khai thác sau khi thành công.",
  ["question2"]: "Làm cách nào để rút tiền?",
  ["answer2"]: "Bạn có thể chuyển đổi số tiền được tạo ra hàng ngày thành USDT, sau đó bắt đầu rút tiền. Việc rút USDT sẽ tự động được gửi đến địa chỉ ví mà bạn đã thêm vào nút và các địa chỉ khác không được hỗ trợ.",
  ["question3"]: "Cách tính thu nhập?",
  ["answer3"]: "Khi bạn tham gia thành công, hợp đồng thông minh bắt đầu tính toán địa chỉ của bạn thông qua nút và bắt đầu tính toán thu nhập.",
  ["audit_report"]: "Báo cáo kiểm toán",
  ["audit_report_tip"]: "chúng tôi có một báo cáo kiểm toán an toàn",
  ["partner"]: "Cộng sự",
  ["partner_tip"]: "đối tác kinh doanh của chúng tôi",
  ["my_account"]: "Tài khoản của tôi",
  ["mining_status"]: "Tình trạng khai thác",
  ["wallet_balance"]: "Số dư trên Wallet",
  ["exchangeable"]: "Có thể trao đổi",
  ["withdrawable"]: "Có thể rút tiền",
  ["share_dividends"]: "Chia cổ tức",
  ["my_level"]: "Trình độ của tôi",
  ["level"]: "Cấp độ",
  ["hash_rate"]: "Hiệu suất Đào",
  ["output_interest"]: "Lãi suất đầu ra",
  ["output_interest_rate"]: "Lãi suất đầu ra",
  ["output_interest_cycle"]: "Chu kỳ lãi đầu ra",
  ["level_details"]: "Chi tiết cấp độ",
  ["pair_price"]: "Giá cặp",
  ["exchange"]: "Đổi",
  ["withdraw"]: "Rút",
  ["redeem_all"]: "Đổi tất cả",
  ["total_balance"]: "Số dư",
  ["record"]: "Ghi lại",
  ["shared"]: "Được chia sẻ",
  ["output"]: "Phần thưởng",
  ["back"]: "Mặt sau",
  ["no-data"]: "Không có dữ liệu",
  ["received"]: "có nhận",
  ["transfer"]: "Chuyển giao",
  ["share_friends"]: "Chia sẻ bạn bè",
  ["my_share_link"]: "Liên kết chia sẻ của tôi",
  ["copy"]: "Sao chép",
  ["share_tip"]: "Gửi liên kết lời mời của bạn, bạn bè tham gia vào nút thông qua liên kết của bạn và bạn sẽ nhận được phần thưởng mã thông báo hào phóng",
  ["join_tip"]: "Bạn đã tham dự",
  ["copySuccess"]: "Liên kết đã được sao chép",
  "apply_mining_pool_rewards": "Áp dụng phần thưởng hồ khai thác",
  "standard": "Tổng tiền",
  "required": "Cần",
  "countdown": "Thời hạn",
  "host_day": "Ngày",
  "reward": "Phần thưởng",
  ["i_know"]: "Tôi biết",
  ["popup_miniing_title"]: "Áp dụng phần thưởng hồ khai thác",
  ["popup_miniing_conn1"]: "Hợp đồng Thông minh ERC-20",
  ["popup_miniing_conn2"]: "Hồ bơi đào hợp đồng thông minh ERC-20 sẽ bắt đầu sự kiện chia sẻ ETH tiếp theo. Đăng ký tham gia ngay bây giờ. Sau khi sự kiện bắt đầu, các nhà cung cấp thanh khoản có thể chia sẻ nhiều phần thưởng khác nhau.",
  ["popup_miniing_btn"]: "Nhận Phần Thưởng",
  "recharge": "Nạp tiền",
  "recharge_currency": "Địa chỉ gửi tiền",
  "recharge_scan code transfer": "Lưu QR",
  "recharge_amount": "Số tiền gửi",
  "please enter": "Vui lòng nhập",
  "recharge_upload": "Nhấn để tải ảnh lên",
  "confirm": "Xác nhận",
  "rachange_link_coinbase": "Kênh nạp tiền chính thức của Coinbase",
  "rachange_link_binance": "Kênh nạp tiền chính thức của Binance",
  "rachange_link_huobi": "Kênh nạp tiền chính thức của Huobi",
  "cancel": "Hủy",
  "addressCopySuccess": "Địa chỉ đã được sao chép",
  "recharge_record": "Lịch sử nạp tiền",
  "all": "Tất cả",
  "processing": "Đang chờ xử lý",
  "credited": "Thành công",
  "fail": "Thất bại",
  "amount": "Số tiền",
  "state": "Trạng thái",
  "time": "Thời gian",
  "record_tip": "Ghi chú"
}