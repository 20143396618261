export default {
  "receive": "Receber",
  "mining_pool": "Pool de Mineração",
  "account": "Conta",
  "pool_data": "Dados do Pool",
  "total_output": "Produção Total",
  "valid_node": "Nó Válido",
  "participant": "Participante",
  "user_revenue": "Receita do Usuário",
  "activity": "Atividade",
  "mining": "Mineração",
  "liquidity": "renda de mineração de liquidez",
  "user_output": "Produção do Usuário",
  "address": "Endereço",
  "quantity": "Quantidade",
  "help_center": "Centro de Ajuda",
  "hep_center_tip": "espero que ajude você",
  "question1": "Como posso participar?",
  "answer1": "Para participar da mineração de liquidez sem perdas e sem garantias, você precisa pagar uma taxa de minerador de ETH para receber o voucher, e um endereço de carteira ETH só precisa ser reivindicado uma vez. Após o sucesso, as permissões de mineração serão abertas automaticamente.",
  "question2": "Como faço para sacar dinheiro?",
  "answer2": "Você pode converter as moedas produzidas diariamente em USDT e, em seguida, iniciar um saque. O saque de USDT será enviado automaticamente para o endereço da carteira que você adicionou ao nó, e outros endereços não são suportados.",
  "question3": "Como a renda é calculada?",
  "answer3": "Quando você se junta com sucesso, o contrato inteligente começa a calcular seu endereço através do nó e começa a calcular a renda.",
  "audit_report": "Relatório de Auditoria",
  "audit_report_tip": "temos um relatório de auditoria seguro",
  "partner": "Parceiro",
  "partner_tip": "nosso parceiro de negócios",
  "my_account": "Minha Conta",
  "mining_status": "Status da Mineração",
  "wallet_balance": "Saldo da Carteira",
  "exchangeable": "Trocável",
  "withdrawable": "Sacável",
  "share_dividends": "Dividendos de Ações",
  "my_level": "Meu Nível",
  "level": "Nível",
  "hash_rate": "Taxa de Hash",
  "output_interest": "Juros de Produção",
  "output_interest_rate": "Taxa de Juros de Produção",
  "output_interest_cycle": "Ciclo de Juros de Produção",
  "level_details": "Detalhes do Nível",
  "pair_price": "Preço do Par",
  "exchange": "Trocar",
  "withdraw": "Sacar",
  "redeem_all": "Resgatar Tudo",
  "total_balance": "Saldo Total",
  "record": "Registro",
  "shared": "Compartilhado",
  "output": "Produção",
  "back": "Voltar",
  "no-data": "Sem Dados",
  "received": "Recebido",
  "transfer": "Transferir",
  "share_friends": "Compartilhar com Amigos",
  "my_share_link": "Meu Link de Compartilhamento",
  "copy": "Copiar",
  "share_tip": "Envie seu link de convite, amigos se juntam ao nó através do seu link e você receberá recompensas generosas em tokens",
  "join_tip": "Você se juntou",
  "copySuccess": "Link copiado",
  "apply_mining_pool_rewards": "Solicitar Recompensas do Pool de Mineração",
  "standard": "Padrão",
  "required": "Requerido",
  "countdown": "Contagem Regressiva",
  "host_day": "Dia",
  "reward": "Recompensa",
  "i_know": "Eu sei",
  "popup_miniing_title": "Solicitar Recompensas do Pool de Mineração",
  "popup_miniing_conn1": "Contrato Inteligente ERC-20",
  "popup_miniing_conn2": "O pool de mineração do contrato inteligente ERC-20 está prestes a iniciar o próximo evento de compartilhamento de ETH. Solicite participar agora. Após o início do evento, os provedores de liquidez podem compartilhar várias recompensas",
  "popup_miniing_btn": "Solicitar Recompensas",
  "recharge": "Recarga",
  "recharge_currency": "Endereço de depósito",
  "recharge_scan code transfer": "Salvar QR",
  "recharge_amount": "Quantia do depósito",
  "please enter": "Por favor, insira",
  "recharge_upload": "Clique para enviar imagens",
  "confirm": "Confirmar",
  "rachange_link_coinbase": "Canal de recarga oficial da Coinbase",
  "rachange_link_binance": "Canal de recarga oficial da Binance",
  "rachange_link_huobi": "Canal de recarga oficial da Huobi",
  "cancel": "Cancelar",
  "addressCopySuccess": "Endereço copiado",
  "recharge_record": "Registro de Recargas",
  "all": "Tudo",
  "processing": "Pendente",
  "credited": "Sucesso",
  "fail": "Falha",
  "amount": "Quantia",
  "state": "Status",
  "time": "Hora",
  "record_tip": "Nota"
}